.hdw-go-back-section {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  button.btn-anchor-style {
    border: none;
    background: none;
    color: $blue;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: none;
    }
  }
}
