.gc-warande-time-line {
  overflow: hidden;
  min-width: 300px;
  width: 100%;

  @media(max-width: 767.5px) {
    max-height: unset !important;
  }

  flex: 1 0 0;
  background-color: $main-color-theme;
  color: white;
  border-radius: 20px;
  padding: #{$sm-space}px;

  .gc-historic-article-title-part {
    max-width: 450px;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
  }

  .gc-historic-chapter-part {
    position: relative;
    width: 50%;
    padding: 20px;

    h2, h3, h4 p {
      word-break: break-word;
    }

    h3 {
      font-size: #{$font-size-xl}px;
      font-weight: bolder;
    }

    h4 {
      font-weight: bold;
      font-size: #{$font-size-lg}px
    }

    .gc-time-line-subsection {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;

      .gc-vertical-seperator {
        width: 4px;
        height: 100%;
        background-color: white;
        position: absolute;

        &::before {
          content: '';
          position: absolute;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          transform: translate(calc(-50% + 4px / 2), -100%);
          border: 4px white solid;
        }
      }
    }

    img:not(.hdw-icon) {
      width: 100%;
      height: auto;
      margin: 12px auto;
      display: block;
    }

    .gc-img-part {
      height: 100%;
      width: 100%;

      position: relative;

      img.hdw-icon {
        max-width: 50px;
        max-height: 50px;
        position: absolute;
        @include center();
      }
    }

    &:nth-child(even) {
      margin-right: 50%;

      .gc-time-line-subsection {
        left: 100%;

        .gc-vertical-seperator::before {
          background-color: $main-color-dark-variant;
        }
      }
    }

    &:nth-child(odd) {
      margin-left: calc(50%);

      .gc-time-line-subsection {
        left: -100%;

        .gc-vertical-seperator {
          left: 100%;

          &::before {
            background-color: $main-color-light-variant;
          }
        }
      }
    }
  }
}
