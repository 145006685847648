.hdw-oldest-house-section {
  .hdw-content-wrapper {
    align-items: center;
    position: relative;
  }

  figure {
    max-width: 650px;
    float: left;
    padding: #{$md-space}px;
  }
}