.hdw-introduction-section {
  .hdw-content {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;

    h1 {
      text-align: center;
      margin-bottom: 24px;
    }

    @media(min-width: #{$min-width-md-pc}px) {
      .hdw-paragraph-wrapper {
        column-count: 2;
      }
    }
  }
}