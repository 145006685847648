.gc-welcome-section {
  color: $main-color-font;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 15px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $secondary-color-theme 100%);
    bottom: 0;
  }
}
