.gc-positioned {


	&.gc-positioned-absolute {
		position: absolute;
	}

	&.gc-positioned-relative {
		position: relative;
	}

	&.gc-positioned-absolute {
		position: absolute;
	}

	&.gc-positioned-horizontally-centered {
		@include center-horizontal();
	}

	&.gc-positioned-vertically-centered {
		@include center-vertical();
	}

	&.gc-positioned-centered {
		@include center();
	}

	&.gc-positioned-relative {
		position: relative;
	}
}
