.gc-shaped {
	&.gc-circle-shaped {
		clip-path: circle();
		shape-outside: circle();
	}

	&.gc-text-balloon-shaped {
		clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
		shape-outside: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
	}

	&.gc-canvas-shaped {
		@include get-clip-shape(polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%));
	}
}