.gc-warande {
  //background-color: $main-color-theme;
  font-size: #{$font-size-sm}px;
  font-family: 'Raleway', sans-serif;

  p {
    text-align: justify;
  }

  a{
    color: blue;
  }

  .gc-welcome-section-header {
    margin: #{$lg-space}px
  }

  .react-images__container {
    .react-images__view {
      display: flex;
    }

    .react-images__navigation {
      button {
        background-color: transparentize($main-color-theme, 0);
      }

      button:hover {
        background-color: transparentize($main-color-theme, 0.3);
      }
    }
  }
}
