.gc-welcome-partial {
  border-radius: 20px;
  border-style: solid;
  background-color: $main-color-theme;
  text-align: center;

  @include center-horizontal(15%);

  max-width: 100%;


  /*******BP-dependeing*****/

  padding: #{$sm-space}px;

  h1 {
    font-size: #{$font-size-xl}px;
  }
}


@media(min-width: #{$min-width-phablet}px) {
  .gc-welcome-partial {
    @include center-horizontal(25%);

    padding: #{$md-space}px;

    h1 {
      font-size: #{$font-size-xxl}px;
    }
  }
}


@media(min-width: #{$min-width-tablet}px) {
  .gc-welcome-partial {
    padding: #{$lg-space}px;
  }
}

@media(min-width: #{$min-width-md-pc}px) {
  .gc-welcome-partial {
    h1 {
      font-size: #{$font-size-xxxl}px;
    }
  }
}

@media(min-width: #{$min-width-lg-pc}px) {
  .gc-welcome-partial {
  }
}
