@import './topStylingDependencies';
@import '../../../Warande/components/secctions/WelcomeSection/homePageTitle';
@import '../../../Warande/components/partials/history/historyPartial';
@import '../../../Warande/components/warande';
@import '../../../Warande/components/partials/TimeLine/warandeTimeLine';

@import '../../../Warande/components/secctions/WelcomeSection/welcomeSection';
@import '../../../Warande/components/secctions/HistorySection/historySection';
@import '../../../Warande/components/secctions/Introduction/introduction';
@import '../../../Warande/components/secctions/OldestHouseSection/oldestHouseSections';
@import '../../../Warande/components/secctions/Restauratie/restauratie';
@import '../../../Warande/components/secctions/CurrentState/currentState';
@import '../../../Warande/subPages/ReturnSection/goBackSection';
@import '../../../Warande/subPages/Restauratie/sections/extendedRestorationSection';


.gc-warande {
  overflow: hidden;

  $vertical-section-margin: 16;

  .hwt-section {
    margin-top: #{$vertical-section-margin}px;
    margin-bottom: #{$vertical-section-margin}px;
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .hwt-section:nth-child(even) {
    $scrollbar-width: 0;
    $gutter-width: calc((100vw - 100% - #{$scrollbar-width}px) / 2);

    /* &::after,*/
    &::before {
      content: '';
      position: absolute;
      width: calc(100vw - #{$scrollbar-width}px);
      height: calc(100% + 2 * #{$vertical-section-margin}px);
      top: -#{$vertical-section-margin}px;
      background-color: $secondary-color-theme;
    }

    &::before {
      left: calc(-1 * #{$gutter-width});
    }
  }

  figure {
    img {
      max-width: 100%;
    }
  }
}

