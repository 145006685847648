/******** MAIN COLOR ***********/

$main-color-theme: #56655e;
$main-color-dark-variant: darken($main-color-theme, 5%);
$main-color-light-variant: lighten($main-color-theme, 5%);

$main-color-font: $white;

/******** SECONDARY COLOR ***********/

$secondary-color-theme: lighten($main-color-theme, 50%);
$secondary-color-dark-variant: darken($secondary-color-theme, 5%);
$secondary-color-light-variant: lighten($secondary-color-theme, 5%);
