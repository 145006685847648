.gc-covered {
	&.gc-covered-width, &.gc-covered-width-and-height {
		width: 100%;
	}

	&.gc-covered-height, &.gc-covered-width-and-height {
		height: 100%;
	}

	&.gc-covered-full-screen {
		@include full-screen();
	}
}