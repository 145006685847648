.gc-section {
	.gc-test::after {
		position: absolute;
		content: '';
		left: 0;
		bottom: 0;
		right: 0;
		top: 0;
		background-color: grey;
	}

}